import React from 'react'

export default function AboutUs() {
  return (
    <>
        <div style={{margin: '100px 50px '}}>
            <p style={{margin: '10px 0px'}}>
            At <b>Tutor's Street</b>,  we provide a platform for tutors and experts to connect with students both online and offline. Learners of all ages and stages can find experts who teach them their desired subject ranging from academics to curricular activities.
            </p>
            <p style={{margin: '10px 0px'}}>
                Learners can get on-demand sessions from the tutors they choose in various subjects like academics (Mathematics, science, languages, etc), Music, and art.</p>
            <p style={{margin: '10px 0px'}}>
            On Tutors Street, you can get experts who can give you personal sessions online as well as offline.</p>
            <p style={{margin: '10px 0px'}}>
            Do you have any questions ? <br/>
            Please email at <span style={{color: 'blue'}}> contact@tutorsstreet.com</span>

</p>

        </div> 
    
    </>
  )
}
