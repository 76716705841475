import {
  Stack,
  Text,
  Title,
  Group,
  ThemeIcon,
  SimpleGrid,
  Box,
  Button,
  Overlay,
  Center,
} from "@mantine/core";
import { FaAward, FaDollarSign, FaHeadset, FaLayerGroup } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import MessageModal from "./MessageModalHero";
import { useState } from "react";

export default function Features() {
  const isSmall = useMediaQuery("(max-width: 1000px)");
  const isLil = useMediaQuery("(max-width: 600px)");


  const [messageModal, setMessageModal] = useState(false)
  
  
  return (
    <>
    <MessageModal close={e => setMessageModal(false)} opened={messageModal} />

    
    <Stack
      sx={{ background: "#cae2ff" }}
      spacing={"xl"}
      // py={isSmall ? 30 : 60}
      // px={isSmall ? 30 : 60}
    >
      <Stack px={isSmall ? "md" : "xl"} spacing={0} style={{textAlign: "center", paddingTop:"60px"}}>
        <Text weight={500}>WHY CHOOSE US</Text>
        <Title>Why turn to Tutor’s Street?</Title>
      </Stack>
      <SimpleGrid
        px={isSmall ? "md" : "xl"}
        mt={36}
        spacing={isSmall ? 32 : 64}
        cols={isSmall ? 1 : 2}
        style={{width: isSmall ? "96%" : "80%", margin: "auto", marginTop: "40px"}}
      >
        {FEATURES.map((f, i) => (
          <Feature {...f} key={i} />
        ))}
      </SimpleGrid>
      <Box
        mt={isSmall ? 30 : 60}
        p={"xl"}
        sx={{
          background: `url(/static/images/fb.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        
        
        <Stack px={isSmall ? "md" : "xl"} spacing={0} style={{textAlign: "center", paddingTop: isSmall ? "60px" : "160px", 
        paddingBottom: isSmall ? "60px" : "160px", zIndex: "1", color: "white", display: "flex", 
        flexDirection: "column", gap: "40px", width: isLil ? "96%" : "80%"}}>
        <Title>What do we have for you?</Title>
        <Text weight={500}>Tutor’s Street is an online platform that gives you access to a wide range of experts to choose from as
 your tutor. No boundaries for subjects or language!
</Text>
        <Button  
        onClick={e => setMessageModal(true)}
          radius={"sm"}
          sx={{
            background: "#cae2ff",
            zIndex: "1",
            transition: 'all .2s ease-in-out',
            "&:hover": {
              background: "#9fcaff",
              transform: "scale(1.02)"
            },
            color: "black",
          }}
          
          size={isSmall ? "lg" : "xl"}
          m={0}
          style={{
            width: "285px",
            display: "block",
            margin: "auto"
          }}
        >
          Post Your Requirement
        </Button>
      </Stack>
        
      </Box>
    </Stack>
    </>

  );
}

function Feature({ Icon, title, text }) {
  const isSmall = useMediaQuery("(max-width: 1000px)");
  return (
    <Group align={"start"} noWrap>
      <ThemeIcon
        sx={{ color: "#66FBD1" }}
        color={"#000"}
        radius={"50%"}
        size={isSmall ? 48 : 64}
      >
        <Icon size={isSmall ? 24 : 32} />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text size={isSmall ? 22 : 26} weight={500}>
          {title}
        </Text>
        <Text>{text}</Text>
      </Stack>
    </Group>
  );
}

const FEATURES = [
  {
    title: "Certified tutors",
    text: "We have the best and highly qualified Tutors",
    Icon: FaAward,
  },
 
  {
    title: "Personalized Sessions",
    text: "Students have one-on-one session with their Tutors",
    Icon: FaLayerGroup,
  },
  {
    title: "Flexible timings",
    text: "Choose your comfort, choose your time",
    Icon: BiTimeFive,
  },
  {
    title: "24*7 Support",
    text: "On demand, Online tutoring",
    Icon: FaHeadset,
  },
];
