import { Stack, Title, Text, Group, Avatar, Box } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";

export default function Testimonials() {
  const isSmall = useMediaQuery("(max-width: 1000px)");
  return (
    <Stack
      sx={{ background: "#3347B0", color: "white" }}
      px={isSmall ? "md" : "xl"}
      py={isSmall ? 30 : 60}
    >
      <div>
        <Text weight={500}
        style={{textAlign: "center"}}
        >TESTIMONIALS</Text>
        <Title
        style={{textAlign: "center"}}
        >Hear from our students!</Title>
      </div>
      <Carousel
        mt={"48px"}
        slideSize={isSmall ? "100%" : "32%"}
        height={200}
        slideGap="md"
        controlsOffset="xs"
        slidesToScroll={isSmall ? 1 : 3}
        align="center"
        loop
      >
        {TESTEMONIALS.map((u, i) => (
          <Carousel.Slide key={i}>
            <UserCard {...u} />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Stack>
  );
}

function UserCard({ image, name, text, occupation }) {
  return (
    <Box
      p={"md"}
      sx={{
        maxWidth: "400px",
        background: "white",
        color: "black",
        borderRadius: "5px",
        height: "200px",
      }}
    >
      <Group>
        <Avatar src={''} alt={name} radius="xl" />
        <div>
          <Text weight={600}>{name}</Text>
          {/* <Text size="sm" color={"dimmed"}>
            {occupation}
          </Text> */}
        </div>
      </Group>
      <Text pl={54} pt={2} size="sm">
        {text}
      </Text>
    </Box>
  );
}

const TESTEMONIALS = [
  {
    image:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    text: "The teaching is a absolutely beneficial. I learned and easily a lot of things in the best way, and the best techniques. They cultivate  to be the best version of themselves during the class. And I’m so grateful that I’m taking this class.",
    occupation: "High school student",
    name: "Nava",
  },
 



  {
    image:
      "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
    text: "This platform helps to find tutors in different areas like maths,music, and other languages nearby as well as online.  ",
    occupation: "",
    name: "Sunny",
  },

    
  {
    image:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
    text: "It is a good platform to search tutors nearby",
    occupation: "",
    name: "Tom",
  },
];
