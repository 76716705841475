import { Accordion, Box, SimpleGrid, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export default function QnA() {
  const isSmall = useMediaQuery("(max-width: 1000px)");
  const isLil = useMediaQuery("(max-width: 600px)");
  return (
    <SimpleGrid mb={isSmall ? 30 : 60} cols={isSmall ? 1 : 2} 
    style={{width: isLil ? "96%" : "80%", margin: isSmall ? "0 auto" : "140px auto"}}
    >
      <Box
        sx={
          isSmall
            ? { 
              background: `url(/static/images/jj.jpg)`,
              // backgroundPosition: "0 -250px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "390px",
             }
            : {
                background: `url(/static/images/jj.jpg)`,
                // backgroundPosition: "0 -250px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100%"
              }
        }
      ></Box>
      <Box
        pr={isSmall ? "md" : 0}
        pl={isSmall ? "40px" : "60px"}
        mb={isSmall ? 20 : 40}
        mt={isSmall ? 20 : 40}
      >
        <Text weight={500}>FREQUENTLY ASKED QUESTIONS</Text>
        <Title>Useful information</Title>

        <Accordion mt="xl">
          {QNA.map(({ panel, control }, i) => (
            <Accordion.Item key={i} value={control}>
              <Accordion.Control>{control}</Accordion.Control>
              <Accordion.Panel>{panel}</Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Box>
      
    </SimpleGrid>
  );
}

const QNA = [

  {
    panel:
      `At Tutor's Street, we provide a platform for tutors and experts to connect with students both online and offline. Learners of all ages and stages can find experts who teach them their desired subject ranging from academics to curricular activities.
      `,
    control: `How does Tutor Street work?`,
  },
  {
    panel:
      `Check out the home page of tutorsstreet.com to find the perfect tutor. Enter your location and select
      your desired subject from the drop-down menu and select from the options.
      `,
    control: `How do I connect with my tutor?`,
  },
  {
    panel:
      `Learners can get on-demand sessions from the tutors they choose in various subjects like academics (Mathematics, science, languages, etc), Music, and art.`,
    control: `What can I get help with?`,
  },
  {
    panel:
      `On Tutors Street, you can get experts who can give you personal sessions online as well as offline.`,
    control: `Where does the tutoring take place?`,
  },
 
   
];
